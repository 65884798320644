import React from "react";
import { Link } from "react-router-dom";

const SeriesListItem = ({ series }) => (
  <li>
    <Link to={`/series/${series.show.id}`}>{series.show.name}</Link>
  </li>
);

const SeriesList = (props) => {
  return (
    <div>
      <ul style={seriesListStyle}>
        {props.list.map((item) => (
          <SeriesListItem series={item} key={item.show.id} />
        ))}
      </ul>
    </div>
  );
};

const seriesListStyle = {
  listStyleType: "none",
  paddingLeft: 0,
};

export default SeriesList;

import React, { Component } from "react";
import Intro from "../components/intro";
import SeriesList from "./series-list";
import Loader from "./Loader";

class Series extends Component {
  state = {
    series: [],
    seriesName: "",
    isFetchting: false,
  };

  handleChange = (e) => {
    this.setState({ seriesName: e.target.value, isFetchting: true });

    const url = `https://api.tvmaze.com/search/shows?q=${e.target.value}`;

    fetch(url)
      .then((response) => response.json())
      .then((json) => this.setState({ series: json, isFetchting: false }));
  };

  render() {
    const { series, seriesName, isFetchting } = this.state;

    return (
      <div>
        <Intro message="Bu sahifada istalgan turdagi seriallar haiqda ma'lumot topish mumkin" />
        <div>
          <input type="text" onChange={this.handleChange} value={seriesName} />
        </div>

        {seriesName.trim() === "" && series.length === 0 && (
          <p>Serial nomini kiriting</p>
        )}

        {!isFetchting && series.length === 0 && seriesName.trim() !== "" && (
          <p>Siz kiritgan nom bo`yicha hech qanday serial topilmadi</p>
        )}
        {isFetchting && <Loader />}
        {!isFetchting && <SeriesList list={this.state.series} />}
      </div>
    );
  }
}

export default Series;

import React from "react";
import loadingLogo from "../assets/loader.gif";

const Loader = () => {
  return (
    <div>
      <img src={loadingLogo} alt="Loading . . ."></img>
    </div>
  );
};

export default Loader;

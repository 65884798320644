import React, { Component } from "react";
import Loader from "../container/Loader";

class SingleSeries extends Component {
  state = {
    show: null,
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    const url = `https://api.tvmaze.com/shows/${id}?embed-episodes`;

    fetch(url)
      .then((response) => response.json())
      .then((json) => this.setState({ show: json }));
  }

  render() {
    const { show } = this.state;
    // console.log(show);
    return (
      <div>
        {show === null && <Loader />}
        {show !== null && (
          <div>
            <p>Nomi : {show.name}</p>
            <p>Premere sanasi : {show.premiered}</p>
            <p>O`rtacha reytingi : {show.rating.average}</p>
            {show.network !== null && (
              <p> Ishlab chiqaruvchi : {show.network.name}</p>
            )}
            {show.officialSite !== null && (
              <p>
                Rasmiy sayt :<a href={show.officialSite}>{show.officialSite}</a>
              </p>
            )}
            <img alt={show.name} src={show.image.medium} />
          </div>
        )}
      </div>
    );
  }
}

export default SingleSeries;
